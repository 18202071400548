import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locale/en';
import ru from './locale/ru';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'ru',
    messages: {
        en,
        ru,
    },
});

export default i18n;
