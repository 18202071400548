import HTTP from './default';

export default {
    getUser(token) {
        return HTTP({           
            url: '/auth/check_token/',
            method: 'GET',
            params: {token},
        });
    },

    getChartUsers(authToken) {
        return HTTP({            
            url: '/orgcharts',
            method: 'GET',
            headers: {
                Authorization: `Token ${authToken}`,
            },            
        });
    },

    getListUsers(authToken, spec_id) {
        return HTTP({            
            url: `/orgcharts/users_by_specialization/${spec_id}`,
            method: 'GET',
            headers: {
                Authorization: `Token ${authToken}`,
            },
        });
    },

    getSpecializations(token) {
        return HTTP({           
            url: '/specializations?applied_only=true',
            method: 'GET',
            params: {token},
        });
    },
};
