<template>
  <div class="page" id="app">
    <router-view />    
  </div>
</template>

<script>

// В библиотеку внесена доработка на 641 стр
import './assets/orgchat_js/jquery.orgchart.js';
import './../node_modules/orgchart/dist/css/jquery.orgchart.css';
import './assets/css/orgchart-main.css';
import './assets/css/orgchart-list.css';
export default {
  data() {
    return {
    };
  }, 
};
</script>
