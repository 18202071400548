export default {
  title: {
    main: 'Оргчарт',
    back: 'вернуться на сайт',
    filter: 'Фильтр по специализациям',
    alTabs: 'Все',
  },
  message: {
      loading: 'Идет загрузка',
      emptyEmpl: 'Сотрудников по данной специализации не найдено',
  },
  user: {
    replace: 'Замещает',
  },
};
