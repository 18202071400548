import Vue from 'vue';
import Vuex from 'vuex';
import Users from '@/api/users';

Vue.use(Vuex);

export default new Vuex.Store({  
  state: {    
    userId: localStorage.getItem('userId'),
    userToken: !Number(process.env.VUE_APP_AUTH) ? localStorage.getItem('authToken') : 'fcb8193dac263a0bd58e0722d9c1624bc12f03fe', // токен взять из админ панели
    isUserInOrgchart: !Number(process.env.VUE_APP_AUTH) ? localStorage.getItem('isUserInSchema') : 'false',   
    orgChartShema: null,
  },
  mutations: {    
    orgChartShema(state, users) {
      state.orgChartShema = users;
    },    
    setUserToken(state, token) {
      state.userToken = token;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setIsUserInOrgchart(state, param) {
      state.isUserInOrgchart = param;
    },
  },
  actions: {
    async getUser({commit}, hash) {
      const token = await Users.getUser(hash);
      if (token.status === 401) {
        commit('setUserToken', null);
        commit('setUserId', null);
      }
      if (token.status === 200) {
        commit('setUserToken', token.data.token);
        commit('setUserId', token.data.user_id);
        commit('setIsUserInOrgchart', String(token.data.is_user_in_orgchart));
      }
    },    

    async getOrgchart({commit, state}) {
      const users = await Users.getChartUsers(state.userToken);
      commit('orgChartShema', users.data);
    },
  },
  getters: {
    usersChart(state) {
      return state.orgChartShema;
    },   
    userToken(state) {
      return state.userToken;
    },
    userId(state) {
      return state.userId;
    },
    isUserInOrgchart(state) {
      return state.isUserInOrgchart;
    },
    firstUserOrgchart(state) {
      return state.orgChartShema.id;
    },
  },
});
