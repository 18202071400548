export default {
  title: {
    main: 'Org Chart',
    back: 'back to site',
    filter: 'Filter by specialization',
    alTabs: 'All',
  },
  message: {
      loading: 'Loading',
      emptyEmpl: 'No employees found in this specialization',
  },
  user: {
    replace: 'Replaces',
  },
};
