import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue'),
    beforeEnter: (to, from, next) => {   
      const defaultAuth = Number(process.env.VUE_APP_AUTH);
      const hash = to.query.token;
      const storageToken = !defaultAuth ? localStorage.getItem('authToken') : true;
      if (storageToken && !hash) {
        store.dispatch('getOrgchart');        
        next();
      } else if (hash) {
        store.dispatch('getUser', hash).then(() => {
          if (store.state.userToken) {
            localStorage.setItem('authToken', store.state.userToken);
            localStorage.setItem('userId', store.state.userId);
            localStorage.setItem('isUserInSchema', store.state.isUserInOrgchart);
            store.dispatch('getOrgchart');            
            next();
          } else {
            next({name: 'Close'});
          }
        });
      } else {
        next({name: 'Close'});
      }
    },
  },
  {
    path: '/no-auth',
    name: 'Close',
    component: () => import('../views/Close.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
